const techTags = {
  react: {
    tech: 'React',
    name: 'FaReact',
    size: 20,
    color: 'deepskyblue',
  },
  nodejs: {
    tech: 'Node.js',
    name: 'DiNodejsSmall',
    size: 20,
    color: 'lightgreen',
  },
  git: {
    tech: 'Git',
    name: 'DiGitMerge',
    size: 20,
    color: 'black',
  },
  javascript: {
    tech: 'JavaScript',
    name: 'DiJsBadge',
    size: 20,
    color: 'yellow',
  },
  css: {
    tech: 'CSS',
    name: 'DiCss3Full',
    size: 20,
    color: 'teal',
  },
  python: {
    tech: 'Python',
    name: 'DiPython',
    size: 20,
    color: 'deepskyblue',
  },
  ruby: {
    tech: 'Ruby',
    name: 'DiRuby',
    size: 20,
    color: 'crimson',
  },
  java: {
    tech: 'Java',
    name: 'FaJava',
    size: 20,
    color: 'wheat',
  },
  angular: {
    tech: 'Angular',
    name: 'DiAngularSimple',
    size: 20,
    color: 'red',
  },
  html: {
    tech: 'HTML',
    name: 'FaHtml5',
    size: 20,
    color: 'darkorange',
  },
  php: {
    tech: 'php',
    name: 'DiPhp',
    size: 20,
    color: 'violet',
  },
  mongodb: {
    tech: 'MongoDB',
    name: 'DiMongodb',
    size: 20,
    color: 'green',
  },
  vscode: {
    tech: 'VS Code',
    name: 'DiVisualstudio',
    size: 20,
    color: 'deepskyblue',
  },
  redux: {
    tech: 'Redux',
    name: 'DiReact',
    size: 20,
    color: 'violet',
  },
  postgres: {
    tech: 'PostgreSQL',
    name: 'DiPostgresql',
    size: 20,
    color: 'blue',
  },
  redis: {
    tech: 'Redis',
    name: 'DiRedis',
    size: 20,
    color: 'crimson',
  },
  mysql: {
    tech: 'MySQL',
    name: 'DiMysql',
    size: 20,
    color: 'DarkCyan',
  },
  mariadb: {
    tech: 'MariaDB',
    name: 'DiDatabase',
    size: 20,
    color: 'darkslategrey',
  },
  sqlite: {
    tech: 'SQLite',
    name: 'DiSqllite',
    size: 20,
    color: 'darkslategrey',
  },
  agile: {
    tech: 'Agile',
    name: 'DiScrum',
    size: 20,
    color: 'blue',
  },
  jquery: {
    tech: 'JQuery',
    name: 'DiJqueryLogo',
    size: 20,
    color: '#7acef4',
  },
  cms: {
    tech: 'CMS',
    name: 'FaPencilAlt',
    size: 20,
    color: 'black',
  },
  clientRelationships: {
    tech: 'Client relationships',
    name: 'FaHandsHelping',
    size: 20,
    color: 'blue',
  },
  teamLeadership: {
    tech: 'Team leadership',
    name: 'FaUserFriends',
    size: 20,
    color: 'black',
  },
  aws: {
    tech: 'AWS',
    name: 'FaAws',
    size: 20,
    color: 'orange',
  },
  docker: {
    tech: 'Docker',
    name: 'FaDocker',
    size: 20,
    color: 'blue',
  },
  sass: {
    tech: 'Sass',
    name: 'FaSass',
    size: 20,
    color: 'pink',
  },
  azure: {
    tech: 'Azure',
    name: 'DiVisualstudio',
    size: 20,
    color: 'blue',
  },
  contentful: {
    tech: 'Contentful',
    name: 'FaDatabase',
    size: 20,
    color: 'darkgrey',
  },
  graphql: {
    tech: 'GraphQL',
    name: 'GrGraphQl',
    size: 20,
    color: 'violet',
  },
  gatsby: {
    tech: 'GatsbyJS',
    name: 'GrGatsbyjs',
    size: 20,
    color: 'purple',
  },
  jest: {
    tech: 'Jest',
    name: 'SiJest',
    size: 20,
    color: 'lightcoral',
  },
  jira: {
    tech: 'Jira',
    name: 'FaJira',
    size: 20,
    color: 'blue',
  },
  reactNative: {
    tech: 'React Native',
    name: 'FaReact',
    size: 20,
    color: 'DeepSkyBlue',
  },
}

export default techTags
